import React from 'react'

const Discount = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7871 21.5024L2.99707 11.7124V13.7124C2.99707 14.2424 3.20707 14.7524 3.58707 15.1224L11.3771 22.9124C12.1571 23.6924 13.4271 23.6924 14.2071 22.9124L20.4171 16.7024C21.1971 15.9224 21.1971 14.6524 20.4171 13.8724L12.7871 21.5024Z" fill="#FFFFFF"/>
<path d="M11.3771 17.9124C11.7671 18.3024 12.2771 18.5024 12.7871 18.5024C13.2971 18.5024 13.8071 18.3024 14.1971 17.9124L20.4071 11.7024C21.1871 10.9224 21.1871 9.65244 20.4071 8.87244L12.6171 1.08244C12.2471 0.712441 11.7371 0.502441 11.2071 0.502441H4.99707C3.89707 0.502441 2.99707 1.40244 2.99707 2.50244V8.71244C2.99707 9.24244 3.20707 9.75244 3.58707 10.1224L11.3771 17.9124ZM4.99707 2.50244H11.2071L18.9971 10.2924L12.7871 16.5024L4.99707 8.71244V2.50244Z" fill="#FFFFFF"/>
<path d="M7.24707 6.00244C7.93743 6.00244 8.49707 5.4428 8.49707 4.75244C8.49707 4.06209 7.93743 3.50244 7.24707 3.50244C6.55671 3.50244 5.99707 4.06209 5.99707 4.75244C5.99707 5.4428 6.55671 6.00244 7.24707 6.00244Z" fill="#FFFFFF"/>
</svg>

  )
}

export default Discount