import React from 'react'

const Indicator = () => {
  return (
<svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="0.5" y1="24" x2="0.5" stroke="#292929"/>
</svg>

  )
}

export default Indicator