import React from 'react'

const FacebookIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2137 21.3283L25.8356 17.2331H21.9452V14.5768C21.9452 13.4562 22.4877 12.3632 24.2301 12.3632H26V8.8768C26 8.8768 24.3945 8.6001 22.8603 8.6001C19.6548 8.6001 17.5616 10.5619 17.5616 14.1119V17.2331H14V21.3283H17.5616V31.2285C18.2767 31.342 19.0082 31.4001 19.7534 31.4001C20.4986 31.4001 21.2301 31.342 21.9452 31.2285V21.3283H25.2137Z" fill="#292929"/>
</svg>

  )
}

export default FacebookIcon